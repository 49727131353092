import { compose, withState, withProps } from "recompose";
import { injectIntl, defineMessages } from "react-intl";
import makeCommandDispatcher from "web/core/apollo/makeCommandDispatcher";

const messages = defineMessages({
  error: {
    id: "modules.User.LoginForm.loginError",
    defaultMessage: "Incorrect username or password.",
  },
});

const EnhanceLoginForm = ({ LoginMutation }) =>
  compose(
    injectIntl,
    withState("canSubmit", "setCanSubmit", false),
    withProps((props) => ({
      onSuccess: () => {
        props.onLoginSuccess();
      },
      onError: () => {
        props?.setLoginError(props.intl.formatMessage(messages.error));
      },
    })),
    makeCommandDispatcher({
      authenticateUser:
        (props) =>
        ({ email, password }) => {
          props?.setLoginError && props.setLoginError(null);
          return {
            options: {
              mutation: LoginMutation,
              refetchQueries: ["User", "Cart", "CartQty"],
              variables: {
                username: email,
                password: password,
              },
            },
          };
        },
    })
  );

export default EnhanceLoginForm;
