import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const StackElement = ({ appearance, children }) => {
  return (
    <div
      className={classNames("stack__element", {
        [appearance]: appearance,
      })}
    >
      {children}
    </div>
  );
};

StackElement.propTypes = {
  appearance: PropTypes.oneOf([
    "only-mobile",
    "desktop-only",
    "tablet-desktop-only",
  ]),
};

const Stack = ({ desktopSize, mobileSize, size, children }) => {
  children = Array.isArray(children) ? children.filter(Boolean) : [children];

  if (!desktopSize && !mobileSize) {
    size = size ?? "2";
  }

  return (
    <div
      className={classNames("stack", {
        [`stack--size-${size}`]: size,
        [`stack--size-mobile-${mobileSize}`]: mobileSize,
        [`stack--size-desktop-${desktopSize}`]: desktopSize,
      })}
    >
      {React.Children.map(children, (element, index) => {
        if (!element) {
          return null;
        }
        if (element?.type === StackElement) {
          return element;
        }
        return <StackElement>{element}</StackElement>;
      })}
    </div>
  );
};

Stack.propTypes = {
  mobileSize: PropTypes.oneOf(["1", "2", "4", "8"]),
  desktopSize: PropTypes.oneOf(["1", "2", "4", "8"]),
  size: PropTypes.oneOf(["1", "2", "4", "8"]),
  appearance: PropTypes.oneOf(["default", "mobile-only", "desktop-only"]),
};

export default Stack;
