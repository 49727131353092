import classNames from "classnames";
import React, { useRef, useState, useEffect, useCallback } from "react";
import Button from "theme/components/atoms/Button";
import useActiveTab from "./useActiveTab";
import  { useMediaQuery } from "react-responsive";
import Breakpoints from "theme/components/atoms/Breakpoints";

/**
 * FC had aready developed a prop to choose if wer want the Tabs component to scroll on click of a tab, it's scrollOnTabSwitch.
 * Aymen did not know that and created another hook for the same behaviour coming from the Synthesis component --> isScrollingTo/setScrollTo.
 * That's why I added (typeof setScrollTo === "function") on line 83 to 85, to be able to disable scroll if setScrollTo is set to false.
 *
 * @param {*} param0
 * @returns
 */
const Tabs = ({ tabs, id, scrollOnTabSwitch, isScrollingTo, setScrollTo }) => {
  const controlsRef = useRef();
  const activeTabRef = useRef();
  const tabContainerRef = useRef();
  const [hasToScroll, setHasToScroll] = useState(false);

  const isDesktop = useMediaQuery({minWidth: Breakpoints.desktop});

  const displayedTabs = tabs.filter(Boolean);

  const [activeKey, setActiveKey] = useActiveTab(
    displayedTabs,
    controlsRef,
    activeTabRef
  );

  const isFirstRender = useRef(true);

  // The scroll "engine" for desktop/mobile.
  const scrollToTab = useCallback(() => {
    const headerElement    = document.getElementById("header");
    const productGalleryEl = document.querySelector('.product-gallery');
    // Get only mobile notice, it's on top so scroll is affected.
    const topNoticeMobile  = document.querySelector('.kap-top-notice-mobile');
    if (productGalleryEl) {
      const headerHeight = headerElement.clientHeight;
      // Get notice height for mobile if exist.
      const topNoticeMobileHeight = topNoticeMobile?.clientHeight || 0;
      const pgHeight = productGalleryEl.clientHeight;
      const boxHeight = tabContainerRef.current.clientHeight;
      const boxTop = parseInt(tabContainerRef.current.getBoundingClientRect().top);

      window.scrollTo({
        top: isDesktop
         ? pgHeight - boxHeight
         : boxTop + document.documentElement.scrollTop - ( headerHeight + topNoticeMobileHeight ),
        left: 0,
        behavior: "smooth",
      });
    }
  }, [isDesktop]);

  // Callback to set active key and got scroll if needed.
  const setActiveKeyCallback = useCallback((key) => {
    setActiveKey(key);
    if (typeof setScrollTo === "function") {
      setHasToScroll(scrollOnTabSwitch);
      setScrollTo(false);
    }
  }, [setHasToScroll, setScrollTo, setActiveKey, scrollOnTabSwitch]);

  // The scroll effect applyed only if hasToScroll.
  useEffect( () =>  {
    if (!hasToScroll || isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setHasToScroll(false);
    scrollToTab();
  }, [activeKey, hasToScroll, setHasToScroll, scrollToTab]);

  // Scroll if outside click set by isScrollingTo.
  useEffect(() => {
    if (!isScrollingTo?.anchor) return;
    setHasToScroll(true);
    setActiveKeyCallback(isScrollingTo.anchor);
  }, [isScrollingTo?.anchor, setActiveKeyCallback]);

  // No display if no tabs.
  if (displayedTabs.length <= 0) {
    return null;
  }

  return (
    <div className="tabs" id={activeKey} ref={tabContainerRef}>
      <div className="tabs__head">
        <div className="tabs__controls" ref={controlsRef} role="tablist">
          {displayedTabs.map(({ key, label }) => {
            /* The direct child returned here must be a button for correct use in a11y */
            return (
              <Button
                appearance={`simple`}
                size="small"
                key={key}
                onClick={() => {
                  setActiveKeyCallback(key);
                }}
                role="tab"
                aria-selected={activeKey === key}
                aria-controls={`tabs-${id}--panel-${key}`}
                id={`tabs-${id}--key-${key}`}
              >
                {label}
              </Button>
            );
          })}
        </div>
      </div>
      {displayedTabs.map(({ key, children }) => (
        <div
          className={classNames("tabs__content", {
            "tabs__content--active": activeKey === key,
          })}
          ref={activeKey === key ? activeTabRef : undefined}
          hidden={activeKey !== key}
          key={key}
          tabIndex="-1"
          role="tabpanel"
          id={`tabs-${id}--panel-${key}`}
        >
          {children}
        </div>
      ))}
    </div>
  );
};

Tabs.defaultProps = {
  id: "0",
};

export default Tabs;
