import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "theme/components/atoms/Button";
import SVGInline from "react-svg-inline";
import facebook from "./facebook.svg?inline";
import Icon from "theme/components/atoms/Icon"


const FacebookLoginButton = ({fbButtonAppearrance="primary", ...props}) => {
  return (
    <div className="facebook-login__button">
      <Button
        appearance={fbButtonAppearrance}
        onClick={(e) => {
          props.onClick();
        }}
        state={props.isDisabled ? "pending" : undefined}
      >
        {fbButtonAppearrance !== "secondary" &&
           <SVGInline svg={facebook} />
        }

        {/* add secondary appearance for button for only login page */}
        {fbButtonAppearrance === "secondary" &&
           <Icon
           icon="ImFacebook2"
           size="med"
         />
        }
        <span>
          <FormattedMessage
            id="components.organisms.Login.LoginForm.facebook"
            defaultMessage="Connect with Facebook"
          />
        </span>
      </Button>
    </div>
  );
};

export default FacebookLoginButton;
