import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Fieldset = ({ children, large, appearance }) => {
  return (
    <fieldset
      className={classNames("fieldset", {
        "fieldset--large": large,
        [`fieldset--${appearance}`]: appearance,
      })}
    >
      {children}
    </fieldset>
  );
};

Fieldset.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Fieldset;
