import React, { useState } from "react";
import { compose } from "recompose";
import { injectIntl, defineMessages, FormattedMessage } from "react-intl";
import Form from "theme/components/atoms/Form/Form";
import { BodyFade } from "theme/components/atoms/Typography/Body";
import EnhanceLoginForm from "./EnhanceLoginForm";
import FormActions from "theme/components/molecules/Form/FormActions";
import Fieldset from "theme/components/atoms/Form/Fieldset";
import { Email, Password } from "theme/components/atoms/Form/Input";
import SubmitButton from "theme/components/atoms/Button/SubmitButton";
import LoginMutation from "./LoginMutation.gql";
import Link from "theme/components/atoms/Typography/Link";
import FormItem from "theme/components/molecules/Form/Item";
import Checkbox from "theme/components/atoms/Form/Input/Checkbox";
import LoginWithFacebook from "theme/modules/User/LoginWithFacebook";
import RequiredFields from "theme/components/atoms/Form/RequiredFields";
import Stack from "theme/components/atoms/Layout/Stack";


const messages = defineMessages({
  submit: {
    id: "modules.User.LoginForm.submit",
    defaultMessage: "Sign in",
  },
  emailLabel: {
    id: "modules.User.LoginForm.email.label",
    defaultMessage: "Email",
  },
  emailErrorRequired: {
    id: "modules.User.LoginForm.email.errorRequired",
    defaultMessage: "Please enter an email",
  },
  emailErrorMalformed: {
    id: "modules.User.LoginForm.email.errorMalformed",
    defaultMessage: "Please enter a valid email address. For example pierremaistre@domaine.com.",
  },
  emailPlaceholder: {
    id: "modules.User.RegisterForm.emailPlaceholder",
    defaultMessage: "ex: name.firstname@mail.com",
  },
  passwordLabel: {
    id: "modules.User.LoginForm.password.label",
    defaultMessage: "Password",
  },
  passwordErrorRequired: {
    id: "modules.User.LoginForm.password.errorRequired",
    defaultMessage: "Please enter a password",
  },
  forgotPassword: {
    id: "modules.User.LoginForm.forgotPassword",
    defaultMessage: "Forgot your password?",
  },
  userSave: {
    id: "modules.User.LoginForm.userSave",
    defaultMessage: "Remember me",
  },
});

const LoginForm = (props) => {
  const { formatMessage } = props.intl;
  const [rememberMe, setRememberMe] = useState(true);

  return (
    <>
      <Form
        onValidSubmit={(data) => props.authenticateUser(data)}
        onChange={props.onChange}
      >
        <Fieldset>
        <Stack desktopSize="2" mobileSize="4">
          <FormItem label={formatMessage(messages.emailLabel)}>
            <Email
              id="email"
              name="email"
              value={props.value?.email}
              required
              aria-label={formatMessage(messages.emailLabel)}
              placeholder={formatMessage(messages.emailPlaceholder)}
              validationErrors={{
                // If form validated but no email
                required: formatMessage(messages.emailErrorRequired),
                // If error email 
                isEmail: formatMessage(messages.emailErrorMalformed),
              }}
              autoComplete="email"
            />
          </FormItem>

          <FormItem label={formatMessage(messages.passwordLabel)}>
            <Password
              id="password"
              name="password"
              required
              aria-label={formatMessage(messages.passwordLabel)}
              placeholder={formatMessage(messages.passwordLabel)}
              validationError={formatMessage(messages.passwordErrorRequired)}
            />
          </FormItem>
          <div className="remember-me">
          <Checkbox
            name="userSave"
            id="userSave"
            checked={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
            label={props.intl.formatMessage(messages.userSave)}
          />
          </div>

          <div className="left">
            <BodyFade>
              <Link to="/account/password-request">
                {formatMessage(messages.forgotPassword)}
              </Link>
            </BodyFade>
          </div>
          <FormActions appearance="full">
            <SubmitButton
              size="big"
              state={props.commandPending ? "pending" : undefined}
              appearance="primary"
            >
              {formatMessage(messages.submit)}
            </SubmitButton>
          </FormActions>

          {props.additionalActions}
          </Stack>
        </Fieldset>
      </Form>

      {props.enableFacebook && (
        <div className="form-actions__element">
          <div className="bordered">
            <span className="login-alt">
              <FormattedMessage
                id="components.organisms.Login.LoginForm.alt"
                defaultMessage="Or"
              />
            </span>
          </div>
          <LoginWithFacebook 
          onLoginSuccess={props.onLoginSuccess}
          fbButtonAppearrance={props.fbButtonAppearrance} />
        </div>
      )}
      <RequiredFields/>
    </>
  );
};

export default compose(
  EnhanceLoginForm({ LoginMutation }),
  injectIntl
)(LoginForm);
