import React from "react";
import { FormattedMessage, defineMessages } from "react-intl";
import classNames from "classnames";

const messages = defineMessages({
  required: {
    id: "modules.User.RegisterForm.requiredFields",
    defaultMessage: "* Required fields",
  },
});

const RequiredFields = ({
  position
}) => {
  return (
    <p className={classNames('required', {
      [`required--${position}`]: position,
    })}>
      <FormattedMessage {...messages.required} />
    </p>
  );
};

export default RequiredFields;