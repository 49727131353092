import React from "react";
import { compose } from "recompose";
import { injectIntl } from "react-intl";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import FacebookLoginButton from "theme/components/organisms/FacebookLoginButton";
import EnhanceLoginWithFacebook from "./EnhanceLoginWithFacebook";
import LoginWithFacebookMutation from "./LoginWithFacebookMutation.gql";
import FacebookConfigurationQuery from "./FacebookConfig.gql";

const LoginWithFacebook = (props) => {
  const responseFacebook = (response) => {
    if (response.accessToken) {
      props.authenticateUserWithFacebook(response);
    }
  };

  if (
    props.facebookConfiguration.loading |
    !props.facebookConfiguration?.storeConfiguration?.isFacebookEnabled
  ) {
    return null;
  }

  return (
    <FacebookLogin
      appId={props.facebookConfiguration?.storeConfiguration?.facebookAppId}
      callback={responseFacebook}
      scope="email"
      isMobile={false}
      isDisabled={
        props.commandPending || props.commandResponse?.success ? true : false
      }
      render={(renderProps) => {
        return <FacebookLoginButton 
        {...renderProps}
        fbButtonAppearrance={props.fbButtonAppearrance} />;
      }}
    />
  );
};

export default compose(
  EnhanceLoginWithFacebook({
    LoginWithFacebookMutation,
    FacebookConfigurationQuery,
  }),
  injectIntl
)(LoginWithFacebook);
