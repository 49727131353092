import { compose, withState, withProps } from "recompose";
import { injectIntl } from "react-intl";
import makeCommandDispatcher from "web/core/apollo/makeCommandDispatcher";
import branchServerClient from "web/core/branchServerClient";
import { graphql } from "react-apollo";

const EnhanceLoginWithFacebook = ({
  LoginWithFacebookMutation,
  FacebookConfigurationQuery,
}) =>
  compose(
    injectIntl,
    withState("canSubmit", "setCanSubmit", false),
    withProps((props) => ({
      onSuccess: () => {
        props?.onLoginSuccess();
      },
      onError: (e) => {},
    })),
    branchServerClient(
      withProps({
        facebookConfiguration: {},
      }),
      graphql(FacebookConfigurationQuery, {
        name: "facebookConfiguration",
      })
    ),
    makeCommandDispatcher({
      authenticateUserWithFacebook: (props) => (data) => {
        const token = {
          access_token: data.accessToken,
          expires_in: data.expiresIn,
        };
        return {
          options: {
            mutation: LoginWithFacebookMutation,
            refetchQueries: ["User", "Cart", "CartQty"],
            variables: {
              facebookToken: token,
              userID: data.userID,
            },
          },
        };
      },
    })
  );

export default EnhanceLoginWithFacebook;
